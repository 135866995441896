<template>
  <cau-hinh-danh-gia />
</template>
<script>
import CauHinhDanhGia from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/danh-gia/CauHinhDanhGia.vue'

export default {
  components: {
    CauHinhDanhGia,
  },
}
</script>
