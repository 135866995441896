<template>
  <b-card>
    <validation-observer
      ref="documentRules"
    >
      <div class="mb-1">
        <b-form-group
          id="input-group-1"
          label="Nhóm tiêu chí"
          label-for="input-1"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true }"
            name="nhomTieuChiId"
          >
            <treeselect
              ref="nhomTieuChiId"
              v-model="nhomTieuChiId"
              v-format-v-select
              :options="nhomTieuChiCbx"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn nhóm tiêu chí"
              :clear-on-select="true"
              class="w-50"
              :normalizer="normalizer"
              @select="chooseNhomTieuChi"
            />
            <span class="label-noti-validate">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <div class="d-flex justify-content-between">
          <button
            class="compact ui button btn-primary"
            @click="addCauHinhDanhGia()"
          >
            Thêm mới
          </button>
          <button
            class="compact ui button btn-primary"
            @click="addTieuChiDanhGia()"
          >
            Lưu
          </button>
        </div>
      </div>
      <div
        v-if="tieuChiDanhGia && tieuChiDanhGia.length > 0"
        class="scroll-bar"
      >
        <!-- padding-4px -->
        <validation-observer ref="commonRules">
          <div
            v-for="(item, index) in tieuChiDanhGia"
            :key="item.id"
            class="mb-1"
          >
            <div class="border-bottom d-flex justify-content-between">
              <span class="d-flex flex-grow-1 mr-1">
                <span class="css-step">Câu
                  <input
                    v-model.number="item.order"
                    placeholder="Số"
                    class="input-number-title-chdg"
                  >
                </span>
                <span class="ml-1 font-weight-700 flex-grow-1">
                  <validation-provider
                    v-slot="{ errors }"
                    :key="`${item.id}_${index}`"
                    :rules="{ required: true }"
                    :name="`${item.id}_${index}`"
                    class="grap-9"
                  >
                    <textarea
                      ref="tenTieuChi"
                      v-model="item.tenTieuChi"
                      v-set-height-text
                      placeholder="Nhập câu hỏi đánh giá"
                      class="border-0 input-title-chdg w-100"
                      rows="1"
                      @input="item.maTieuChi = item.tenTieuChi"
                    />
                    <span class="label-noti-validate font-weight-500">{{ errors[0] }}</span>
                  </validation-provider>
                </span>
              </span>
              <span class="d-flex justify-content-end align-items-center margin-right-5">
                <copy-icon
                  v-b-tooltip.hover.top.v-secondary
                  title="Sao chép"
                  size="16"
                  class="mr-1 cursor-pointer custom-class"
                  @click="copyCauHinhDanhGia(item)"
                />
                <trash-2-icon
                  v-b-tooltip.hover.top.v-secondary
                  title="Xóa"
                  size="16"
                  class="cursor-pointer custom-class"
                  @click="deleteCauHinhDanhGia(index, item.id)"
                />
              </span>
            </div>
            <br>
            <div
              v-for="(ele, idx) in item.thuocTinhTieuChiDanhGiaBaseModels"
              :key="`${ele.tieuChiDanhGiaId}_${idx}`"
              class="custom-grid-xl-container row-gap-10 mb-1"
            >
              <validation-provider
                v-slot="{ errors }"
                :key="`${ele.tieuChiDanhGiaId}_${idx}_tenThuocTinh`"
                :rules="{ required: true }"
                :name="`${ele.tieuChiDanhGiaId}_${idx}_tenThuocTinh`"
                class="grap-9"
              >
                <b-form-input
                  ref="tenThuocTinh"
                  v-model="ele.tenThuocTinh"
                  v-trim-input
                  placeholder="Nhập tên thuộc tính"
                  @input="ele.maThuocTinh = ele.tenThuocTinh"
                />
                <span class="label-noti-validate">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :key="`${ele.tieuChiDanhGiaId}_${idx}_soDiem`"
                :rules="{ required: true }"
                :name="`${ele.tieuChiDanhGiaId}_${idx}_soDiem`"
                class="grap-2"
              >
                <b-form-input
                  ref="soDiem"
                  v-model.number="ele.soDiem"
                  v-block-e
                  v-format-positive-integers
                  v-is-int
                  v-format-number-null
                  placeholder="Nhập số điểm"
                  type="number"
                />
                <span class="label-noti-validate">{{ errors[0] }}</span>
              </validation-provider>
              <div class="action-chdg text-center grap-1">
                <plus-square-icon
                  v-if="idx + 1 === item.thuocTinhTieuChiDanhGiaBaseModels.length"
                  v-b-tooltip.hover.v-secondary
                  title="Thêm mới"
                  size="16"
                  class="mr-1 cursor-pointer custom-class"
                  @click="addThuocTinhDanhGia(index, item)"
                />
                <trash-2-icon
                  v-b-tooltip.hover.v-secondary
                  title="Xóa"
                  size="16"
                  class="cursor-pointer custom-class"
                  @click="deleteThuocTinhDanhGia(index, idx, ele.id)"
                />
              </div>
            </div>
          </div>
        </validation-observer>
      </div>
      <div
        v-else
        class="mb-1 mt-1"
      >
        <div
          class="padding-10px border-grey-cor background-grey-cor text-center text-primary-cor"
        >
          Vui lòng nhấn Thêm mới để khởi tạo cấu hình đánh giá
        </div>
      </div>
      <common-modal
        ref="confirmDelete"
        title="Xác nhận"
        size="sm"
        @handle-ok="onDelete"
      />
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import { CopyIcon, Trash2Icon, PlusSquareIcon } from 'vue-feather-icons'
import END_POINTS from '@/api/endpoints'
import _cloneDeep from 'lodash/cloneDeep'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    CopyIcon,
    Trash2Icon,
    PlusSquareIcon,
    BFormGroup,
    BFormInput,
    CommonModal,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      nhomTieuChiCbx: [],
      tieuChiDanhGia: [],
      nhomTieuChiId: null,
      componentName: null,
      deleteParentId: null,
      deleteChildId: null,
      deleteId: null,
    }
  },
  async created() {
    await this.getNhomTieuChiCbx()
    if (this.nhomTieuChiCbx && this.nhomTieuChiCbx.length > 0) {
      this.nhomTieuChiId = this.nhomTieuChiCbx[0].id
      const payload = {
        maTieuChi: '',
        tenTieuChi: '',
        listNhomTieuChiId: [this.nhomTieuChiId],
        listNhomTieuChiText: '',
        listPhuongThucDanhGiaId: [],
        listPhuongThucDanhGiaText: '',
        ghiChu: '',
        pageNumber: 1,
        pageSize: 99999,
      }
      this.tieuChiDanhGia = await this.getDanhSachTieuChiDanhGia(payload)
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    async getDanhSachTieuChiDanhGia(payload) {
      const result = await this.$axios.post(END_POINTS.TIEU_CHI_DANH_GIA.DS, payload, false)
      return result.data?.data || []
    },
    async getNhomTieuChiCbx() {
      await this.$axios.get(END_POINTS.NHOM_TIEU_CHI.COMBOBOX).then(response => {
        if (response.data?.succeeded) {
          this.nhomTieuChiCbx = response.data.data
        }
      })
    },
    async chooseNhomTieuChi(data) {
      const payload = {
        maTieuChi: '',
        tenTieuChi: '',
        listNhomTieuChiId: [data.id],
        listNhomTieuChiText: '',
        listPhuongThucDanhGiaId: [],
        listPhuongThucDanhGiaText: '',
        ghiChu: '',
        pageNumber: 1,
        pageSize: 99999,
      }
      this.tieuChiDanhGia = await this.getDanhSachTieuChiDanhGia(payload)
    },
    addCauHinhDanhGia(item = null) {
      let chItem = {
        id: null,
        nhomTieuChiId: this.nhomTieuChiId,
        tenTieuChi: null,
        maTieuChi: null,
        thuocTinhTieuChiDanhGiaBaseModels: [{
          tenThuocTinh: null,
          maThuocTinh: null,
          soDiem: 0,
          status: true,
        }],
        order: this.tieuChiDanhGia?.length + 1 || 1,
      }
      if (item) {
        chItem = item
      }
      this.tieuChiDanhGia.unshift(chItem)
    },
    deleteCauHinhDanhGia(index, id) {
      this.$refs.confirmDelete.showModal()
      this.componentName = 'xoaTieuChiDanhGia'
      this.deleteParentId = index
      this.deleteId = id
    },
    addThuocTinhDanhGia(index, item) {
      let chItem = {
        id: null,
        tenThuocTinh: null,
        soDiem: 0,
        status: true,
      }
      if (item.id) {
        chItem = {
          ...chItem,
          tieuChiDanhGiaId: item.id,
        }
      }
      this.tieuChiDanhGia[index].thuocTinhTieuChiDanhGiaBaseModels.push(chItem)
    },
    onDelete() {
      if (this.componentName === 'xoaTieuChiDanhGia') {
        if (this.deleteId) {
          this.$axios.delete(END_POINTS.TIEU_CHI_DANH_GIA.XOA, {
            listId: [this.deleteId],
          }, false).then(res => {
            if (res.data?.succeeded && res.data?.data[0].succeeded) {
              this.tieuChiDanhGia = this.tieuChiDanhGia.filter((item, index) => index !== this.deleteParentId)
              this.$toast.success(res.data?.data[0].message)
            } else {
              this.$toast.error(res.data?.data[0].message)
            }
          })
        } else {
          this.tieuChiDanhGia = this.tieuChiDanhGia.filter((item, index) => index !== this.deleteParentId)
          this.$toast.success('Xóa tiêu chí đánh giá thành')
        }
      } else if (this.componentName === 'xoaThuocTinhDanhGia') {
        if (this.deleteId) {
          this.$axios.delete(END_POINTS.THUOC_TINH_TIEU_CHI.XOA, {
            listId: [this.deleteId],
          }, false).then(res => {
            if (res.data?.succeeded && res.data?.data[0].succeeded) {
              this.tieuChiDanhGia[this.deleteParentId].thuocTinhTieuChiDanhGiaBaseModels = this.tieuChiDanhGia[this.deleteParentId].thuocTinhTieuChiDanhGiaBaseModels.filter((item, index) => index !== this.deleteChildId)
              this.$toast.success(res.data?.data[0].message)
            } else {
              this.$toast.error(res.data?.data[0].message)
            }
          })
        } else {
          this.tieuChiDanhGia[this.deleteParentId].thuocTinhTieuChiDanhGiaBaseModels = this.tieuChiDanhGia[this.deleteParentId].thuocTinhTieuChiDanhGiaBaseModels.filter((item, index) => index !== this.deleteChildId)
          this.$toast.success('Xóa thuộc tính đánh giá thành công')
        }
      }
    },
    copyCauHinhDanhGia(item) {
      const itemClone = _cloneDeep(item)
      const chItem = {
        ...itemClone,
        id: null,
        thuocTinhTieuChiDanhGiaBaseModels: itemClone.thuocTinhTieuChiDanhGiaBaseModels.map(ele => ({
          ...ele,
          id: null,
        })),
        order: this.tieuChiDanhGia?.length + 1,
      }
      this.addCauHinhDanhGia(chItem)
    },
    deleteThuocTinhDanhGia(index, idx, id) {
      this.$refs.confirmDelete.showModal()
      this.componentName = 'xoaThuocTinhDanhGia'
      this.deleteParentId = index
      this.deleteChildId = idx
      this.deleteId = id
    },
    async addTieuChiDanhGia() {
      const validate = await this.$refs.documentRules.validate()
      if (validate) {
        const payloadThem = {
          tieuChiDanhGiaBaseModels: this.tieuChiDanhGia.filter(item => !item.id).map(item => ({
            maTieuChi: item.maTieuChi,
            nhomTieuChiId: item.nhomTieuChiId,
            order: item.order,
            status: true,
            tenTieuChi: item.tenTieuChi,
            thuocTinhTieuChiDanhGiaBaseModels: item.thuocTinhTieuChiDanhGiaBaseModels.map(ele => ({
              maThuocTinh: ele.maThuocTinh,
              tenThuocTinh: ele.tenThuocTinh,
              tieuChiDanhGiaId: ele.tieuChiDanhGiaId,
              soDiem: ele.soDiem,
              order: ele.order,
              status: true,
            })),
          })),
        }
        if (payloadThem && payloadThem.tieuChiDanhGiaBaseModels?.length > 0) {
          this.$axios.post(END_POINTS.TIEU_CHI_DANH_GIA.THEM, payloadThem).then(res => {
            if (res.data?.succeeded) {
              this.chooseNhomTieuChi({ id: this.nhomTieuChiId })
            }
          })
        }
        const payloadSua = {
          tieuChiDanhGiaBaseModels: this.tieuChiDanhGia.filter(item => item.id).map(item => ({
            ...item,
            thuocTinhTieuChiDanhGiaBaseModels: item.thuocTinhTieuChiDanhGiaBaseModels.map(ele => {
              if (!ele.id) {
                return {
                  maThuocTinh: ele.maThuocTinh,
                  tenThuocTinh: ele.tenThuocTinh,
                  tieuChiDanhGiaId: ele.tieuChiDanhGiaId,
                  soDiem: ele.soDiem,
                  order: ele.order,
                  status: true,
                }
              }
              return ele
            }),
          })),
        }
        if (payloadSua && payloadSua.tieuChiDanhGiaBaseModels?.length > 0) {
          this.$axios.put(END_POINTS.TIEU_CHI_DANH_GIA.SUA, payloadSua).then(res => {
            if (res.data?.succeeded) {
              this.chooseNhomTieuChi({ id: this.nhomTieuChiId })
            }
          })
        }
      } else {
        const first = Object.entries(this.$refs.documentRules.errors).find(item => item[1].length > 0)
        this.handleFocusError(first)
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['nhomTieuChiId'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        }
      }
    },
  },
}
</script>
